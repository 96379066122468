import React from 'react';

import './UxBanner.css'

export default class UxBanner extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            redirect: null
        }

    }


    render() {

        return (

            <div className='UxBanner'>
                <img className='UxBannerImage' src='https://aletphysio.co.za/assets/banner.jpg' />
                <img className='UxBannerLogo' src='https://aletphysio.co.za/assets/logo.jpg' />
                <h1>Alet Richards Physiotherapy</h1>
                <p>
                    With almost two decades experience in various arenas like sport injuries, post surgery and rehabilitation.. Alet is an excellent diagnostician and therapist.
                </p>
            </div>

        )

    }

}
