import React from 'react';

import UxBanner from './components/UxBanner/UxBanner'
import UxFooter from './components/UxFooter/UxFooter'
import UxTreatments from './components/UxTreatments/UxTreatments';
import LandingPage from './pages/LandingPage/LandingPage'

import './App.css';

export default class App extends React.Component {

  constructor(props) {

    super(props)

  }


  componentDidMount() {
  }


  render() {
    return (
      <div className='App'>

        <UxBanner />
        <UxTreatments />
        <UxFooter />

      </div>
    )
  }

}
